import * as React from "react";
import { navigate } from "gatsby-link";
import Layout from "../../components/Layout";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import emailjs from '@emailjs/browser';

import tr from "date-fns/locale/tr";
registerLocale("tr", tr);
// function encode(data) {
//   return Object.keys(data)
//     .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
//     .join("&");
// }

export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isValidated: false };
  }
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  handleDate = (e) => {
    this.setState({ date: e });
    this.date = e;
    this.setState({ date: e.toISOString() });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    emailjs.sendForm('service_lgiun9g', 'template_ea2afa9', form, 'NdkIrSGCIhaNIJaHF')
 .then(() => navigate(form.getAttribute("action")))
  .catch((error) => alert(error));
    // fetch("/asasdasd", {
    //   method: "POST",
    //   headers: { "Content-Type": "application/x-www-form-urlencoded" },
    //   body: encode({ 
    //     "form-name": form.getAttribute("name"),
    //     ...this.state,
    //   }),
    // })
    //   .then(() => navigate(form.getAttribute("action")))
    //   .catch((error) => alert(error));
  };

  render() {
    return (
      <Layout>
        <section className="section">
          <div className="container">
            <div className="content">
              <h1>Online Randevu</h1>
              <form
                name="contact"
                method="post"
                action="/contact/thanks/"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={this.handleSubmit}
              >
                {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                <input type="hidden" name="form-name" value="contact" />
                <div hidden>
                  <label>
                    Don’t fill this out:{" "}
                    <input name="bot-field" onChange={this.handleChange} />
                  </label>
                </div>
                <div className="field">
                  <label className="label" htmlFor={"name"}>
                    İsim & Soyisim
                  </label>

                  <div className="control">
                    <input
                      className="input"
                      type={"text"}
                      name={"name"}
                      onChange={this.handleChange}
                      id={"name"}
                      required={true}
                    />
                  </div>
                </div>
                <div className="field">
                  <label className="label" htmlFor={"phoneNumber"}>
                    Telefon
                  </label>

                  <div className="control">
                    <input
                      className="input"
                      type={"tel"}
                      name={"phoneNumber"}
                      onChange={this.handleChange}
                      id={"name"}
                      required={true}
                    />
                  </div>
                </div>

                <div className="field">
                  <label className="label" htmlFor={"email"}>
                    E-posta
                  </label>
                  <div className="control">
                    <input
                      className="input"
                      type={"email"}
                      name={"email"}
                      onChange={this.handleChange}
                      id={"email"}
                      required={true}
                    />
                  </div>
                </div>

                <div className="field">
                  <label className="label" htmlFor={"date"}>
                    Randevu Tarihi
                  </label>
                  <div className="control">
                    <DatePicker
                      className="input"
                      type={"date"}
                      minDate={new Date}
                      dateFormat="Pp"
                      locale="tr"
                      id={"date"}
                      required={true}
                      showTimeSelect
                      name={"date"}
                      selected={this.date}
                      onChange={this.handleDate}
                    />
                  </div>
                </div>
                <div className="field">
                  <label className="label" htmlFor={"carBrand"}>
                    Aracınızın Markası
                  </label>
                  <div className="control">
                    <select
                      className="input"
                      type={"text"}
                      id={"carBrand"}
                      onChange={this.handleChange}
                      name={"carBrand"}
                      defaultValue="default"
                    >
                      <option disabled={true} value="default">
                        Aracınızın Markası
                      </option>
                      <option value="volkswagen">Volkswagen</option>
                      <option value="audi">Audi</option>
                      <option value="seat">Seat</option>
                      <option value="skoda">Skoda</option>
                      <option value="porsche">Porsche</option>
                      <option value="other">Diğer</option>
                    </select>
                  </div>
                </div>
                <div className="field">
                  <label className="label" htmlFor={"fueltype"}>
                  Yakıt Tipi
                  </label>
                  <div className="control">
                    <select
                      className="input"
                      type={"text"}
                      id={"fueltype"}
                      onChange={this.handleChange}
                      name={"fueltype"}
                      defaultValue="default"
                    >
                      <option disabled={true} value="default">
                       Yakıt Tipi
                      </option>
                      <option value="dizel">Dizel</option>
                      <option value="benzin">Benzin</option>
                      <option value="lpg">LPG</option>
                      <option value="hibrit">Hibrit</option>
                    </select>
                  </div>
                </div>  
                <div className="field">
                  <label className="label" htmlFor={"carKM"}>
                  Aracınızın Km'si

                  </label>
                  <div className="control">
                    <input
                      className="input"
                      type={"number"}
                      name={"carKM"}
                      onChange={this.handleChange}
                      id={"carKM"}
                      required={true}
                    />
                  </div>
                </div>
                <div className="field">
                  <label className="label" htmlFor={"message"}>
                    Mesaj
                  </label>
                  <div className="control">
                    <textarea
                      className="textarea"
                      name={"message"}
                      onChange={this.handleChange}
                      id={"message"}
                      required={true}
                    />
                  </div>
                </div>
                <div className="field">
                  <button className="button is-link" type="submit">
                    Yolla
                  </button>
                </div>
              </form>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}
